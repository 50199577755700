import { Box, Button, ButtonProps, Text, VStack } from "@chakra-ui/react";
import { Link as RouteLink } from "react-router";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { OuterBox } from "../components/OuterBox";
import { useLogin } from "../contexts/LoginContext";

export const regularButtonProps: ButtonProps = {
  // "borderWidth" is ignored
  border: "solid 2px",
  borderColor: "brand.800",
  background: "brand.50",
  paddingX: 3,
  margin: 2,
  _hover: {
    background: "white",
  },
};

export function RegistrationBox(props: { thanks?: boolean }) {
  const { thanks } = props;
  const [login] = useLogin();

  return (
    <OuterBox name="registration">
      <VStack width="100%" spacing={5} paddingBottom={5}>
        <Text>
          {thanks ? (
            <>
              Möchten Sie <b>einen weiteren Programmpunkt</b> anmelden?
            </>
          ) : (
            "Welchen Programmpunkt möchten Sie anmelden?"
          )}
        </Text>
        <Button
          rightIcon={<ChevronRightIcon width="2em" height="2em" />}
          width={{ base: "95%", md: "80%" }}
          as={RouteLink}
          to="/new/music"
          {...regularButtonProps}
        >
          <Text width="100%" fontSize={{ base: "sm", sm: "2xl" }}>
            Musikalische Darbietung
          </Text>
        </Button>
        <Button
          rightIcon={<ChevronRightIcon width="2em" height="2em" />}
          width={{ base: "95%", md: "80%" }}
          as={RouteLink}
          to="/new/hut"
          {...regularButtonProps}
        >
          <Text width="100%" fontSize={{ base: "sm", sm: "2xl" }}>
            Programm im Märlihuus
          </Text>
        </Button>
        <Button
          rightIcon={<ChevronRightIcon width="2em" height="2em" />}
          width={{ base: "95%", md: "80%" }}
          as={RouteLink}
          to="/new/other"
          {...regularButtonProps}
        >
          <Text width="100%" fontSize={{ base: "sm", sm: "2xl" }}>
            Alle anderen Programmpunkte
          </Text>
        </Button>
        {login?.role === "master" || login?.role.startsWith("dept.") ? (
          <>
            <Box width="100%" height="1px" backgroundColor="cyan.800" />
            <Button
              rightIcon={<ChevronRightIcon width="2em" height="2em" />}
              width={{ base: "95%", md: "80%" }}
              as={RouteLink}
              to="/new/special"
              {...regularButtonProps}
              borderColor="cyan.800"
              color="cyan.800"
            >
              <Text width="100%" fontSize={{ base: "sm", sm: "2xl" }}>
                OK: Event (Führung, Samichlaus, …)
              </Text>
            </Button>
            <Button
              rightIcon={<ChevronRightIcon width="2em" height="2em" />}
              width={{ base: "95%", md: "80%" }}
              as={RouteLink}
              to="/new/article"
              {...regularButtonProps}
              borderColor="cyan.800"
              color="cyan.800"
            >
              <Text width="100%" fontSize={{ base: "sm", sm: "2xl" }}>
                OK: Redaktioneller Beitrag
              </Text>
            </Button>
          </>
        ) : null}
      </VStack>
    </OuterBox>
  );
}
