import { NO_DATE } from "../util/NoDate";

export function EmptyCalendar() {
  return (
    <>
      <input type="hidden" name="when[0].date" value={NO_DATE} />
      <input type="hidden" name="when[0].time" value="00:00" />
    </>
  );
}
