import React, { useState, useRef, ReactNode } from "react";
import {
  Box,
  Button,
  Image,
  Link,
  VStack,
  Text,
  Progress,
  HStack,
  Spacer,
  Input,
  useToast,
  chakra,
} from "@chakra-ui/react";
import axios from "axios";
import { InnerBox } from "./InnerBox";
import { ImageSchemaWithFile, ImageTypes } from "shared";
import { boringButtonProps } from "../App";
import { FaFileUpload } from "react-icons/fa";
import {
  CheckCircleIcon,
  DeleteIcon,
  DownloadIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { TextInput } from "./TextInput";

export type UploadState =
  | "IDLE"
  | "UPLOADING"
  | "UPLOAD_SUCCESS"
  | "UPLOAD_FAILURE";
export type Locale = string;
export type Translations = Partial<Record<Locale, ComponentTranslation>>;
type ComponentTranslation = Partial<Record<Component, ConditionalText>>;
type ConditionalText = Partial<Record<UploadState, string>>;
type Component = "heading" | "subheading";

type Props = {
  name: string;
  varName?: string;
  allowedFileTypes?: string[];
  headers?: {};
  baseUrl: string;
  optional?: boolean;
  imageFile?: ImageSchemaWithFile;
  setImageFile?: (newImage: ImageSchemaWithFile | null) => void;
  textName?: string;
  text?: string;
  landscape?: boolean;
  highRes?: boolean;
  download?: boolean;
  children?: ReactNode;
  isReadOnly?: boolean;
};

const thumbProbeOrder: ReadonlyArray<ImageTypes> = [
  "thumb.jpeg",
  "jpeg",
  "png",
  "svg",
] as const;

function thumbType(imageFile: ImageSchemaWithFile) {
  for (const t of thumbProbeOrder) {
    if (t in (imageFile.types ?? {})) {
      return { file: imageFile.file, type: t, ...imageFile.types![t]! };
    }
  }
  return null;
}

function ImageDownloads(props: {
  imageFile: ImageSchemaWithFile;
  baseUrl: string;
}) {
  const { imageFile, baseUrl } = props;
  return (
    <HStack key={`imgDown.${imageFile.file}`}>
      <DownloadIcon color="gray.600" />
      <Text key="text">
        <chakra.span key="down">Download: </chakra.span>
        {Object.entries(imageFile.types ?? {})
          .filter(([k, v]) => !k.includes("."))
          .map(([k, v], i) => (
            <>
              <chakra.span key={`$sep.${k}`}>{i === 0 ? "" : ", "}</chakra.span>
              <Link
                key={k}
                target="_blank"
                href={`${baseUrl}/${imageFile.file}/${k}`}
              >
                {k.toUpperCase()}
              </Link>
            </>
          ))}
      </Text>
    </HStack>
  );
}

function ImageQualityDescription(props: {
  imageFile: ImageSchemaWithFile;
  landscape?: boolean;
  highRes?: boolean;
}) {
  const { imageFile, landscape, highRes } = props;
  if (Object.keys(imageFile.types ?? {}).length === 0) {
    return null;
  } else {
    const firstKey = Object.keys(imageFile.types!)[0] as ImageTypes;
    const firstValue = imageFile.types![firstKey]!;
    switch (firstKey) {
      // They have no dimensions themselves, infer from PNG they were converted to for orientation
      // Resolution is assumed to be good enough
      case "svg":
      case "pdf":
        if (landscape) {
          if (
            (imageFile.types!.png?.width ?? 0) >
            (imageFile.types!.png?.height ?? 0)
          ) {
            return (
              <HStack alignItems="baseline">
                <CheckCircleIcon color="green" />
                <Text>Querformat OK</Text>
              </HStack>
            );
          } else {
            return (
              <HStack alignItems="baseline">
                <WarningIcon color="red" />
                <Text lineHeight="120%">
                  Kein Querformat (Upload trotzdem möglich, wird aber von der
                  Redaktion auf Querformat 16:9 beschnitten)
                </Text>
              </HStack>
            );
          }
        } else {
          return null;
        }
      case "png":
      case "jpeg":
        return (
          <VStack alignItems="left">
            {landscape ? (
              firstValue.width > firstValue.height ? (
                <HStack alignItems="baseline">
                  <CheckCircleIcon color="green" />
                  <Text>Querformat OK</Text>
                </HStack>
              ) : (
                <HStack alignItems="baseline">
                  <WarningIcon color="red" />
                  <Text lineHeight="120%">
                    Kein Querformat (Upload trotzdem möglich, wird aber von der
                    Redaktion auf Querformat 16:9 beschnitten)
                  </Text>
                </HStack>
              )
            ) : null}
            {highRes ? (
              firstValue.width >= 1000 ? (
                <HStack alignItems="baseline">
                  <CheckCircleIcon color="green" />
                  <Text>Auflösung OK</Text>
                </HStack>
              ) : (
                <HStack alignItems="baseline">
                  <WarningIcon color="red" />
                  <Text lineHeight="120%">
                    {
                      "Auflösung zu gering (<1000 Pixel Breite; Upload trotzdem möglich, Druckqualität vielleicht ungenügend)"
                    }
                  </Text>
                </HStack>
              )
            ) : null}
          </VStack>
        );
      default:
        return null;
    }
  }
}

// WARNING: This overrides `setImageFile` param
export function MaybeUploadableImage(
  props: Props & {
    image: number;
    lastImage: number;
    setLastImage: (num: number) => void;
  }
) {
  const { imageFile, image, lastImage, setLastImage, ...rest } = props;
  if (imageFile?.types) {
    if (image > lastImage) {
      setLastImage(image);
    }
  }
  // Render if we:
  // - have data on our own, or
  // - if the previous image has data
  if (imageFile?.types || image <= lastImage + 1) {
    return (
      <UploadableImage
        imageFile={imageFile}
        {...rest}
        setImageFile={() => {
          if (image > lastImage) {
            setLastImage(image);
          }
        }}
      />
    );
  } else {
    return null;
  }
}

export function UploadableImage(props: Props) {
  const {
    name,
    varName,
    baseUrl,
    optional,
    headers = {},
    imageFile,
    setImageFile,
    textName,
    text,
    landscape,
    highRes,
    download,
    allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "application/pdf",
    ],
    children,
    isReadOnly,
  } = props;
  // <0: Idle; >=0: Uploading
  const [uploadProgress, setUploadProgress] = useState<number>(-1);
  const [images, setImages] = useState<ImageSchemaWithFile | null>(
    imageFile ?? null
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  function handleUploadProgress(event: any) {
    const { loaded, total } = event;
    setUploadProgress((loaded / total) * 100);
  }

  async function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length !== 1) {
      return;
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        ...headers,
      },
      onUploadProgress: handleUploadProgress,
    };

    try {
      setUploadProgress(0);
      const response = await axios.post<ImageSchemaWithFile>(
        baseUrl,
        formData,
        config
      );
      setUploadProgress(-1);
      setImages(response.data);
      setImageFile?.(response.data);
    } catch (error) {
      setUploadProgress(-1);
      if (axios.isAxiosError(error)) {
        toast({
          status: "error",
          title: "Upload fehlgeschlagen",
          description: "Bitte wenden Sie sich an das Märlistadt-OK",
        });
      } else {
        console.error("handleFileSelect error: " + JSON.stringify(error));
      }
    }
  }

  const thumb = images ? thumbType(images) : null;
  return (
    <InnerBox name={name} key={name}>
      {/* Top: Progress bar or image or empty */}
      {uploadProgress >= 0 ? (
        <Box paddingY="1em" width="100%">
          <Progress
            size="md"
            width="full"
            colorScheme="brand"
            hasStripe
            value={uploadProgress}
          />
        </Box>
      ) : thumb &&
        images /* This is just to make the compiler know that the validity of thumb implies the validity of imageFile */ ? (
        <VStack width="100%">
          <Image
            maxWidth="100%"
            width={thumb.width}
            src={`${baseUrl}/${thumb.file}/${thumb.type}`}
          />
          <InnerBox nested vSpacing={0} alignItems="left">
            <ImageQualityDescription
              key="iqd"
              imageFile={images}
              landscape={landscape}
              highRes={highRes}
            />
            {download ? (
              <ImageDownloads key="idl" imageFile={images} baseUrl={baseUrl} />
            ) : null}
          </InnerBox>
        </VStack>
      ) : null}

      {/* Bottom: Action buttons and information */}
      {isReadOnly ? null : (
        <HStack width="100%">
          <Button
            onClick={() => inputRef?.current?.click()}
            leftIcon={<FaFileUpload />}
            {...boringButtonProps}
          >
            {thumb
              ? "Ersetzen"
              : `Hochladen${optional ? " (optional)" : " (notwendig)"}`}
          </Button>
          <Spacer />
          {thumb && optional && (
            <Button
              leftIcon={<DeleteIcon />}
              onClick={() => {
                setImages(null);
                setImageFile?.(null);
              }}
            >
              Löschen
            </Button>
          )}
        </HStack>
      )}
      {children}
      {thumb && textName && !isReadOnly ? (
        <TextInput name={textName} defaultValue={text} />
      ) : null}
      <input
        ref={inputRef}
        hidden={true}
        type="file"
        multiple={false}
        accept={allowedFileTypes.join(",")}
        onChange={handleFileSelect}
      />
      <Input type="hidden" name={varName ?? name} value={images?.file ?? ""} />
    </InnerBox>
  );
}
