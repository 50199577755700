import { useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowUpIcon,
  CheckCircleIcon,
  DeleteIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import {
  EMPTY_ON_PURPOSE,
  EventSchema,
  eventSchema,
  ImageSchemaWithFile,
  RoleSchema,
  minDesc,
  maxDesc,
} from "shared";
import { OuterBox } from "../components/OuterBox";
import { trpc } from "../util/trpc";
import {
  useSchemaZorm,
  dataZorm,
  subDataZorm,
  SchemaZorm,
  stringDataZorm,
  boolDataZorm,
} from "../util/dataZorm";
import { boringButtonProps, dangerButtonProps, textInputTheme } from "../App";
import { TextInput } from "../components/TextInput";
import { DecemberCalendar } from "../components/DecemberCalendar";
import { apiUrl } from "../constants";
import { InnerBox } from "../components/InnerBox";
import {
  MaybeUploadableImage,
  UploadableImage,
} from "../components/UploadableImage";
import { HelpText } from "../components/HelpText";
import { CountingTextarea } from "../components/CountingTextarea";
import { HelpfulCheckbox } from "../components/HelpfulCheckbox";
import { ErrorText } from "../components/ErrorText";
import { CenteredSpinner } from "../components/CenteredSpinner";
import { useLogin } from "../contexts/LoginContext";
import { useUndo } from "../hooks/useUndo";
import { SmallPrint } from "../components/SmallPrint";
import { Discussion } from "../components/Discussion";
import { Assignment } from "../components/Assignment";
import { EmptyCalendar } from "../components/EmptyCalendar";

function ValidationStatus(props: { zo: SchemaZorm<typeof eventSchema> }) {
  const { zo } = props;
  const { t } = useTranslation("");

  if (
    zo.validation &&
    (zo.validation as any).error &&
    (zo.validation as any).error.issues
  ) {
    console.warn((zo.validation as any).error.issues);
    return (
      <Text color="red">
        <WarningTwoIcon />
        {` ${t("errors.counter", {
          count: (zo.validation as any).error.issues.length,
        })} `}
        <ArrowUpIcon />
      </Text>
    );
  } else if (zo.validation?.success) {
    return (
      <Text color="green">
        <CheckCircleIcon /> {t("errors.submit")}
      </Text>
    );
  } else {
    return <Text>&nbsp;</Text>;
  }
}

const emptyEvent: EventSchema = {
  what: "",
  who: "",
  where: "",
  when: [],
  desc: "",
  img1: { file: "", uploaded: Date.now(), types: {} },
  home: "",
  facebook: "",
  insta: "",
  spotify: "",
  some: "",
  contact: {
    name: "",
    tel: "",
    mail: "",
    street: "",
    town: "",
    future: true,
  },
  comment: "",
  assignee: "new",
};

export type NewRouteParams = {
  kind: "music" | "hut" | "other" | "special" | "article";
};

export function RecoverEvent(props: {}) {
  const [undo] = useUndo(eventSchema, "eventUndo");
  return <EventEditor event={undo!} kind="other" />;
}
export function NewEvent(props: {}) {
  const { kind = "other" } = useParams<NewRouteParams>();
  return <EventEditor event={emptyEvent} kind={kind} />;
}

export function EditEvent(props: {}) {
  const { eventId } = useParams<{ eventId: string }>();
  const toast = useToast();
  const navigate = useNavigate();
  const eventQuery = trpc.event.get.useQuery(eventId ?? "", {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000,
  });
  if (eventQuery.isLoading) {
    return <CenteredSpinner />;
  } else if (eventQuery.isError) {
    toast({
      status: "error",
      title: "Kann Event nicht laden",
      description: eventQuery.error.message,
    });
    navigate("/");
    return null;
  } else {
    return <EventEditor event={eventQuery.data} kind="special" />;
  }
}

function mayModify(role?: RoleSchema) {
  return (
    role &&
    (role === "master" || role.startsWith("dept.") || role.startsWith("pub."))
  );
}

export function EventEditor(
  props: { event: EventSchema & { _id?: string } } & NewRouteParams
) {
  const { event, kind } = props;
  const [, setUndo] = useUndo(eventSchema, "eventUndo");
  const navigate = useNavigate();
  const [login] = useLogin();
  const createMutation = trpc.event.create.useMutation();
  const updateMutation = trpc.event.update.useMutation();
  const deleteMutation = trpc.event.delete.useMutation();
  const toast = useToast();
  const [where, setWhere] = useState(event.where); // Unvalidated!
  const { t } = useTranslation("");
  const notTiny = useBreakpointValue({ base: false, md: true });
  const [icon, setIcon] = useState<ImageSchemaWithFile | null>(
    event.logo ?? null
  );
  const [lastImage, setLastImage] = useState<number>(0);
  const zo = useSchemaZorm("event", eventSchema, {
    onValidSubmit(e) {
      function onSuccess() {
        toast({
          status: "success",
          title: reportId
            ? "Programmpunkt eingereicht"
            : "Programmpunkt aktualisiert",
          description: t("report.create.submitted_body"),
        });
        if (reportId) {
          navigate("/");
        } else {
          navigate("/thanks");
        }
      }
      e.preventDefault();
      if (reportId) {
        updateMutation.mutate({ ...e.data, _id: reportId }, { onSuccess });
      } else {
        createMutation.mutate(e.data, { onSuccess });
      }
    },
  });
  const dz = dataZorm(zo, event);
  const dzc = subDataZorm(dz, "contact");
  const dzi = subDataZorm(dz, "internal");
  const disabled = zo.validation?.success === false;
  const reportId = event._id;
  const editable = !reportId || login?.role === "master";
  const editableMore = editable || login?.role.startsWith("dept.");

  function validationButtons() {
    return (
      <Stack
        direction={{ base: "column", md: "row" }}
        width="100%"
        marginBottom={6}
      >
        <Button
          disabled={disabled}
          type="submit"
          isLoading={createMutation.isLoading || updateMutation.isLoading}
          border="solid 3px"
        >
          <Text marginTop={1}>{reportId ? "Aktualisieren" : "Einreichen"}</Text>
        </Button>
        {notTiny ? <Spacer /> : <Box height={3} />}
        <Button
          {...boringButtonProps}
          border="solid 2px"
          onClick={() => navigate("/")}
        >
          <Text marginTop={1}>
            {mayModify(login?.role) ? "Zurück" : "Abbrechen"}
          </Text>
        </Button>
        {reportId == null || login?.role !== "master" ? null : (
          <Button
            isLoading={deleteMutation.isLoading}
            {...dangerButtonProps}
            rightIcon={<DeleteIcon />}
            onClick={() => {
              deleteMutation.mutate(reportId, {
                onSuccess: () => {
                  toast({
                    status: "success",
                    title: t("report.delete.submitted"),
                    description: t("report.delete.submitted_body"),
                  });
                  setUndo(event);
                  navigate("/");
                },
              });
            }}
          >
            <Text marginTop={1}>{t("delete")}</Text>
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <Box width="100%" backgroundColor="myellow.50">
      <Center>
        {/* @ts-ignore */}
        <Helmet>
          <title>Märlistadt-Programm: {event.who || "Neuer Eintrag"}</title>
        </Helmet>
        <VStack
          width="min(100vw, 40rem)"
          paddingX={2}
          fontSize={{ base: "md", md: "xl" }}
        >
          <form ref={zo.ref} style={{ width: "100%" }}>
            <OuterBox
              name={kind === "article" ? "event.entry" : "event.calendar"}
            >
              {
                // Predefined values for music and article
                kind === "music" ? (
                  <Heading
                    size="xl"
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="bold"
                    as="h3"
                    width="100%"
                    paddingRight="auto"
                    textColor="gray.700"
                    paddingBottom={2}
                  >
                    Musikalische Darbietung
                    <Input
                      type="hidden"
                      name={zo.fields.what()}
                      value="Musik in der Märlistadt"
                    />
                  </Heading>
                ) : kind === "article" ? (
                  <Heading
                    size="xl"
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="bold"
                    as="h3"
                    width="100%"
                    paddingRight="auto"
                    textColor="gray.700"
                    paddingBottom={2}
                  >
                    Redaktioneller Beitrag
                    <Input
                      type="hidden"
                      name={zo.fields.what()}
                      value="Redaktioneller Beitrag"
                    />
                  </Heading>
                ) : (
                  <TextInput
                    isReadOnly={!editableMore}
                    {...stringDataZorm(dz, "what")}
                  />
                )
              }
              {
                // Different label text for article
                kind === "article" ? (
                  <TextInput
                    isReadOnly={!editableMore}
                    {...stringDataZorm(dz, "who")}
                    label={t("whoArticle")}
                  />
                ) : (
                  <TextInput
                    isReadOnly={!editableMore}
                    {...stringDataZorm(dz, "who")}
                  />
                )
              }

              {
                // Predefined values for hut and article
                kind === "hut" ? (
                  <Input
                    type="hidden"
                    name={dz.fields.where()}
                    value="Märlihuus"
                  />
                ) : kind === "article" ? (
                  <Input
                    type="hidden"
                    name={dz.fields.where()}
                    value="Keine Ahnung"
                  />
                ) : (
                  <>
                    <VStack width="100%">
                      <HStack width="100%" alignItems="baseline">
                        <Text
                          minWidth="6em"
                          marginTop={2}
                          fontSize={{ base: "xl", md: "2xl" }}
                        >
                          {t("where")}
                        </Text>
                        <Select
                          fontSize={{ base: "xl", md: "2xl" }}
                          isReadOnly={!editableMore}
                          key="user"
                          name={dz.fields.where()}
                          placeholder="🠋"
                          defaultValue={dz.data.where}
                          width="100%"
                          onChange={(e) =>
                            setWhere(
                              e.currentTarget.value as EventSchema["where"]
                            )
                          }
                        >
                          {Object.keys(eventSchema.shape.where._def.schema.enum)
                            .filter((c) => c !== "")
                            .map((c) => (
                              <option key={c} value={c}>
                                {c}
                              </option>
                            ))}
                        </Select>
                      </HStack>
                      <HelpText name="where" paddingBottom={0} />
                    </VStack>
                    {where === "Andere" ? (
                      <TextInput
                        isReadOnly={!editableMore}
                        {...stringDataZorm(dz, "elsewhere")}
                      />
                    ) : null}
                  </>
                )
              }

              {kind === "article" ? (
                <EmptyCalendar />
              ) : (
                <DecemberCalendar dz={dz} />
              )}
            </OuterBox>
            <OuterBox name="event.booklet">
              <MaybeUploadableImage
                isReadOnly={!editableMore}
                key="img1"
                name="img1"
                varName="img1.file"
                imageFile={dz.data.img1}
                baseUrl={`${apiUrl}/image`}
                textName="img1.attribution"
                text={dz.data.img1.attribution}
                landscape
                highRes
                download={mayModify(login?.role)}
                image={1}
                lastImage={lastImage}
                setLastImage={setLastImage}
              >
                <ErrorText error={dz.errors.img1()} />
              </MaybeUploadableImage>
              <MaybeUploadableImage
                isReadOnly={!editableMore}
                key="img2"
                name="img2"
                varName="img2.file"
                baseUrl={`${apiUrl}/image`}
                imageFile={dz.data.img2}
                textName="img2.attribution"
                text={dz.data.img2?.attribution}
                highRes
                optional
                download={mayModify(login?.role)}
                image={2}
                lastImage={lastImage}
                setLastImage={setLastImage}
              />
              <MaybeUploadableImage
                isReadOnly={!editableMore}
                key="img3"
                name="img3"
                varName="img3.file"
                baseUrl={`${apiUrl}/image`}
                imageFile={dz.data.img3}
                textName="img3.attribution"
                text={dz.data.img3?.attribution}
                highRes
                optional
                download={mayModify(login?.role)}
                image={3}
                lastImage={lastImage}
                setLastImage={setLastImage}
              />
              <MaybeUploadableImage
                isReadOnly={!editableMore}
                key="img4"
                name="img4"
                varName="img4.file"
                baseUrl={`${apiUrl}/image`}
                imageFile={dz.data.img4}
                textName="img4.attribution"
                text={dz.data.img4?.attribution}
                highRes
                optional
                download={mayModify(login?.role)}
                image={4}
                lastImage={lastImage}
                setLastImage={setLastImage}
              />
              <MaybeUploadableImage
                isReadOnly={!editableMore}
                key="img5"
                name="img5"
                varName="img5.file"
                baseUrl={`${apiUrl}/image`}
                imageFile={dz.data.img5}
                textName="img5.attribution"
                text={dz.data.img5?.attribution}
                highRes
                optional
                download={mayModify(login?.role)}
                image={5}
                lastImage={lastImage}
                setLastImage={setLastImage}
              />
              <UploadableImage
                isReadOnly={!editableMore}
                key="logo"
                name="logo"
                varName="logo.file"
                baseUrl={`${apiUrl}/image`}
                imageFile={icon ?? undefined}
                textName="logo.attribution"
                text={dz.data.logo?.attribution}
                setImageFile={setIcon}
                optional
                download={mayModify(login?.role)}
              />

              <InnerBox name="bookletText">
                <HelpText
                  preserve
                  name={
                    kind === "special"
                      ? "bookletTextOK"
                      : icon === null
                      ? "bookletTextLong"
                      : "bookletTextShort"
                  }
                />
                <CountingTextarea
                  isReadOnly={!editableMore}
                  min={minDesc(
                    kind in ["music", "hut", "other"],
                    icon !== null
                  )}
                  max={maxDesc(
                    kind in ["music", "hut", "other"],
                    icon !== null
                  )}
                  ignoreReturn={!mayModify(login?.role)}
                  width="100%"
                  height="18em"
                  name={zo.fields.desc()}
                  defaultValue={dz.data.desc}
                  {...textInputTheme}
                />
                <ErrorText error={zo.errors.desc()} />
              </InnerBox>
              <InnerBox name="links">
                <TextInput
                  isReadOnly={!editableMore}
                  {...stringDataZorm(dz, "home")}
                />
                <TextInput
                  isReadOnly={!editableMore}
                  {...stringDataZorm(dz, "facebook")}
                />
                <TextInput
                  isReadOnly={!editableMore}
                  {...stringDataZorm(dz, "insta")}
                />
                <TextInput
                  isReadOnly={!editableMore}
                  {...stringDataZorm(dz, "spotify")}
                />
                <TextInput
                  isReadOnly={!editableMore}
                  {...stringDataZorm(dz, "some")}
                />
              </InnerBox>
            </OuterBox>

            <OuterBox name="event.contact">
              <TextInput
                isReadOnly={!editableMore}
                {...stringDataZorm(dzc, "name")}
              />
              {
                // Contact information EMPTY_ON_PURPOSE for articles

                (
                  [
                    "tel",
                    "mail",
                    "street",
                    "town",
                  ] as (keyof typeof dzc.fields)[]
                ).map((f) =>
                  kind === "article" ? (
                    <input
                      type="hidden"
                      name={"contact." + f}
                      value={EMPTY_ON_PURPOSE}
                    />
                  ) : (
                    <TextInput
                      isReadOnly={!editableMore}
                      {...stringDataZorm(dzc, f)}
                    />
                  )
                )
              }
              {kind === "article" ? null : (
                <>
                  <Box width="100%" height="1px" backgroundColor="brand.100" />
                  <HelpfulCheckbox
                    isReadOnly={!editable}
                    {...boolDataZorm(dzc, "future")}
                  />
                </>
              )}
            </OuterBox>

            <OuterBox name="comments">
              <Textarea
                isReadOnly={!editable}
                width="100%"
                name={zo.fields.comment()}
                defaultValue={dz.data.comment}
                {...textInputTheme}
              />
            </OuterBox>

            {
              // Organizer's fields
              mayModify(login?.role) ? (
                <>
                  <OuterBox name="intern">
                    <TextInput {...stringDataZorm(dzi, "fee")} />
                    <TextInput {...stringDataZorm(dzi, "coupons")} />
                    <TextInput {...stringDataZorm(dzi, "travel")} />
                    <VStack width="100%" spacing="0">
                      <Text marginRight="auto" marginBottom="0">
                        OK-Notizen (z.B. allfällige Stückelungen)
                      </Text>
                      <Textarea
                        width="100%"
                        name={zo.fields.internal.notes()}
                        defaultValue={dz.data.internal?.notes}
                        placeholder="OK-Notizen"
                        {...textInputTheme}
                      />
                    </VStack>
                    <VStack width="100%" spacing="0">
                      <Text marginRight="auto" marginBottom="0">
                        Wichtige Hinweise an Helfer vor Ort
                      </Text>
                      <Textarea
                        width="100%"
                        name={zo.fields.internal.helpers()}
                        defaultValue={dz.data.internal?.helpers}
                        placeholder="Hinweise"
                        {...textInputTheme}
                      />
                    </VStack>
                  </OuterBox>
                  <Discussion dz={dz} />
                  <Assignment dz={dz} />
                </>
              ) : (
                <SmallPrint />
              )
            }

            <ValidationStatus zo={zo} />
            {
              // Show mutation error messages
              [updateMutation, createMutation, deleteMutation].map((m) =>
                m.error ? (
                  <Text color="red">Fehler beim Einreichen!</Text>
                ) : null
              )
            }
            {validationButtons()}
          </form>

          <Box width="100%" height="5em" />
        </VStack>
      </Center>
    </Box>
  );
}
