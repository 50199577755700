import {
  Link,
  Image,
  Text,
  useDisclosure,
  useColorModeValue,
  useBreakpointValue,
  HStack,
  Button,
  ButtonProps,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router";
import { FiLogOut } from "react-icons/fi";
import { NavigationBar } from "./NavigationBar";
import { useLogin } from "../contexts/LoginContext";
import logo from "../assets/logo.svg";

export const headerButtonProps: ButtonProps = {
  // "borderWidth" is ignored
  border: "solid 2px",
  borderColor: "brand.50",
  color: "brand.50",
  textColor: "brand.50",
  paddingX: 3,
  margin: 2,
  _hover: {
    background: "brand.600",
  },
};

export function Header() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [login, setLogin] = useLogin();
  const boxShadow = useColorModeValue("md", "none");
  const logoWidth = useBreakpointValue({ base: "80px", sm: "160px" });

  return (
    <NavigationBar
      position="sticky"
      top="0"
      zIndex={999}
      background="brand.900"
      color="myellow.500"
      logo={
        <Link
          paddingX={2}
          rounded="md"
          as={RouteLink}
          to="/"
          _hover={{ textDecoration: "none", backgroundColor: "brand.600" }}
        >
          <HStack alignItems="center">
            <Image src={logo} width={logoWidth} />
            <Text
              paddingTop={2}
              color="myellow.500"
              fontSize={{ base: "xl", sm: "4xl", lg: "6xl" }}
            >
              Programmplaner
            </Text>
          </HStack>
        </Link>
      }
      boxShadow={boxShadow}
      isOpen={isOpen}
      onToggle={onToggle}
      onClose={onClose}
      paddingX={{ base: 4, lg: 6, xl: 8 }}
      paddingY={2}
      textColor="gray.700"
    >
      {login && (
        <>
          <Text color="brand.50">{login.name}</Text>
          <Button
            leftIcon={<FiLogOut />}
            onClick={() => setLogin(null)}
            {...headerButtonProps}
          >
            <Text>Logout</Text>
          </Button>
        </>
      )}
    </NavigationBar>
  );
}
