"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxDesc = exports.minDesc = exports.MAX_DESC = exports.MIN_DESC = void 0;
exports.MIN_DESC = 20;
exports.MAX_DESC = 2000;
function minDesc(normal, logo) {
    if (normal) {
        if (logo) {
            return 140;
        }
        else {
            return 240;
        }
    }
    else {
        return exports.MIN_DESC;
    }
}
exports.minDesc = minDesc;
function maxDesc(normal, logo) {
    if (normal) {
        if (logo) {
            return 240;
        }
        else {
            return 340;
        }
    }
    else {
        return exports.MAX_DESC;
    }
}
exports.maxDesc = maxDesc;
